<template>
  <index-page-title>
    <template #title>
      <span class="text-v-ui-root-monochrome-0">
        {{ $t('domains.domains.pages.commercial.title-part-1') }}
        <br class="xl:hidden" />
        {{ $t('domains.domains.pages.commercial.title-part-2') }}
        <br />
        {{ $t('domains.domains.pages.commercial.title-part-3') }}
      </span>
    </template>

    <template #description>
      <span class="text-v-ui-root-monochrome-5">
        {{ $t('domains.domains.pages.commercial.description-1') }}
        <br class="xl:hidden" />
        {{ $t('domains.domains.pages.commercial.description-2') }}
        <br class="xl:hidden" />
        {{ $t('domains.domains.pages.commercial.description-3') }}
        <br />
        {{ $t('domains.domains.pages.commercial.description-4') }}
        <br class="xl:hidden" />
        {{ $t('domains.domains.pages.commercial.description-5') }}
        <br class="xl:hidden" />
        {{ $t('domains.domains.pages.commercial.description-6') }}
        <br />
        {{ $t('domains.domains.pages.commercial.description-7') }}
        <br class="xl:hidden" />
        {{ $t('domains.domains.pages.commercial.description-8') }}
      </span>
    </template>

    <template #buttons>
      <v-button
        :label="$t('common.general-words.subscribe-on-telegram')"
        class="!bg-v-ui-root-add-another-2 !hover:bg-v-ui-root-add-another-2 !text-v-ui-text-monochrome"
        size="l"
        :rounded="true"
        type="primary"
        @click="openLink(LINKS.subscribe)"
      />
      <v-button
        :label="$t('common.general-words.read-a-post')"
        class="border-v-ui-root-monochrome-0 hover:border-v-ui-root-monochrome-0 !text-v-ui-root-monochrome-0"
        size="l"
        :rounded="true"
        type="outline"
        @click="openLink(LINKS.post)"
      />
    </template>
  </index-page-title>
</template>

<script>
import { VButton } from '@doctis.front/doctis.designsystem';
import IndexPageTitle from '../index-page-title/IndexPageTitle';

export default {
  name: 'CommercialTitle',
  components: {
    IndexPageTitle,

    VButton
  },
  data() {
    return {};
  },
  computed: {
    LINKS() {
      return {
        subscribe: 'https://t.me/+pAPBJdjUHas4NjYy',
        post: 'https://t.me/doctis_ru/411'
      };
    }
  },
  methods: {
    openLink(link) {
      window.open(link);
    }
  }
};
</script>

<style scoped></style>
